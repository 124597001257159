let eventSource = null;
export default {
  eventSource: eventSource,
  start() {
    eventSource = new EventSource(`${process.env.VUE_APP_API_BASE_URL}events`);
    eventSource.onerror = (e) => {};
    return eventSource;
  },
  stop() {
    eventSource.close();
  },
};
