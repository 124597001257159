import { getUserDataRequest } from "@/api";
import { defineStore } from "pinia";
import axiosInstance from "@/api/apiConfig";
import { MATCHING_STATUSES } from "@/utils/сonsts";
import sseService from "@/api/sseService";
import { logoutRequest } from "@/api/authService";
const { STARTED, IN_PROGRESS } = MATCHING_STATUSES;
export const useUserStore = defineStore("user", {
  state: () => ({
    email: "",
    name: "",
    paymentMatchingStatus: "NOT_STARTED",
    smsSendingStatus: "NOT_STARTED",
    isSmsListUpdated: true,
  }),
  getters: {
    isAuthenticated: (state) => !!state.email,
    isMatchingStarted: (state) => [STARTED, IN_PROGRESS].includes(state.paymentMatchingStatus),
    isSmsSendingStarted: (state) => [STARTED, IN_PROGRESS].includes(state.smsSendingStatus),
  },
  actions: {
    async getUserData() {
      try {
        const { email, name } = await getUserDataRequest();
        this.$patch({ email, name });
      } catch (e) {
        console.warn(e);
      }
    },
    async logout() {
      sseService.stop();
      await logoutRequest();
      this.$reset();
      delete axiosInstance.defaults.headers.common.Authorization;
      localStorage.setItem("token", "");
      await this.$router.push({ name: "Login" });
    },
  },
});
