import axios from "axios";
// import { useRouter } from "vue-router";
import { useUserStore } from "@/store";
// import { refreshTokenRequest } from "@/api/index";

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}api`,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
});

// const originalRequest = {}; // for refresh token logic

// Axios interceptors
axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error.response.status === 401) {
      const $userStore = useUserStore();
      // const $router = useRouter();
      // const $toast = useToast();
      $userStore.$reset();
      // await $router.push({ name: "Login" });
      // Vue.$toast.add({ severity: "success", summary: "Success Message", detail: "Order submitted", life: 3000 });
      // $toast.add({
      //   severity: "error",
      //   summary: "Session expired",
      //   detail: "Your authentication was expired. Please re-login!",
      //   life: 5000,
      // });
    }
    // for refresh token logic:
    // originalRequest.config = error.config;
    // if (error.response && error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   try {
    //     const response = await refreshTokenRequest();
    //     console.log("intercept response", response);
    //     originalRequest.config.headers.Authorization = `Bearer ${response.token}`;
    //     await axios.request(originalRequest.config);
    //   } catch (e) {
    //     // logout
    //     return Promise.reject(e);
    //   }
    // }
    // $toast.add({
    //   severity: "error",
    //   summary: "System Error",
    //   detail: "Please try again later",
    //   life: 3000,
    // });
    return Promise.reject(error);
  }
);

export default axiosInstance;
